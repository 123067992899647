//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import defaultImg from '@/assets/shopinspect/note.png'
import { Toast } from "vant";
import { LocalStorage } from "quasar";
export default {
  name: "Home",
  data() {
    return {
      defaultImg:defaultImg,
      imageHost:'https://bxn-qy-workspace.oss-cn-hangzhou.aliyuncs.com/',
      basicInfo: {
        truename: "",
        store: "",
        date: "",
        paperId:'',
        name:''
      },
      date:'',
      showCate: false,
      loading: false,
      orderId: 0,
      showCalendar: false,
      showStore: false,
      storeSearch: "",
      storeListArr: [],
      storeList:[],
      paperTypeTree:[],
      paperItemsAll:[],//全部题目
      paperItemsAllNoAnswered:[],//未答题目
      minDate:new Date(this.$moment().subtract(30, 'days')),
      orderGroupId:'',
      storeid:0,
      answerNum:0,
      questionNum:0
    };
  },
  created() {
    console.log(this.$route.query);
    this.orderId = this.$route.query.id ? this.$route.query.id : 0;
    this.storeid = this.$route.query.storeid ? this.$route.query.storeid : 0;
    this.orderGroupId = this.$route.query.orderGroupId ? this.$route.query.orderGroupId : 0;
    this.basicInfo.paperId = this.$route.query.paperId ? this.$route.query.paperId : '';
    this.basicInfo.name = this.$route.query.title ? this.$route.query.title : '';
    if(!this.$route.query.id){
      this.createStoreList();
    }
  },
  mounted() {
    window.document.title = "巡检单";
    this.basicInfo.truename = LocalStorage.getItem("userName");
  },
  activated() {
    window.document.title = "巡检单";
     this.initLoad();
  },
  methods: {
    onSubmit() {
      console.log("onSubmit");
      this.$router.push(
        "/stockcheck/detail?code=" +
          this.productCode.toUpperCase() +
          "&shop=" +
          this.currentStore
      );
    },
    chooseCalendar(e){
      if(!this.showCate){
        this.minDate = new Date(this.$moment().subtract(30, 'days'));
        this.showCalendar = true;
      }
    },
    onConfirmCalendar(date) {
        this.basicInfo.wdate = `${date[0].getFullYear()}-${
          date[0].getMonth() + 1
        }-${date[0].getDate()}`;
        this.basicInfo.edate = `${date[1].getFullYear()}-${
        date[1].getMonth() + 1
      }-${date[1].getDate()}`;
      this.date = this.basicInfo.wdate + ' 至 ' + this.basicInfo.edate
      this.showCalendar = false;
    },
    //创建巡检表
    createOrder() {
      let postData = {
        paperId:this.basicInfo.paperId,
        name:this.basicInfo.store + this.basicInfo.name,
        orderGroupId:Number(this.orderGroupId),
        storeid: this.basicInfo.store,
        wdate: this.basicInfo.wdate,
        edate: this.basicInfo.edate,
      };
      this.loading = true;
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer +
           "/store/patrol/order/create",
          postData
        )
        .then((res) => {
          this.loading = false;
          if (res.data && res.data.code == 0) {
            this.orderId = res.data.data;
            this.initLoad()
          } else {
            this.$q.notify({
              color: "red-5",
              textColor: "white",
              icon: "warning",
              position: "center",
              message: res.data.msg,
            });
          }
        })
        
    },
    onStoreSearch(e) {
      this.storeListArr = this.storeList.filter(item =>{
        return item.text.indexOf(e) > -1
      })
    },
    storeNameConfirm(store) {
      console.log(store);
      this.basicInfo.store = store.value;
      this.showStore = false;
    },
    createStoreList() {
        this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer +
           "/common/store/listv1",
            {
              "pager": {
                "pageIndex": 1,
                "pageSize": 100,
                "rowsCount": 0,
                "start": 0
              },
              "queryMap": {
                "storeStatus": "",
                "brand": "ZBXN",
                "businessChannel": "201,202,203,206,212,213,214,215",
                "storeTypeCrm": "01,04,05,06,07,15,16,17,19,21,22,23,24,25,26,27,28,29,30,31",
                "storeids": this.storeid,
                "storeType": "2"
              },
              "queryMapObject": {}
            }
        )
        .then((res) => {
          this.loading = false;
          if (res.data && res.data.code == 0) {
            if(res.data.data.length > 0){
              res.data.data.map(item =>{
                this.storeListArr.push({ text: item.storeid + item.title, value: item.storeid });
                
              })
              this.storeList = JSON.parse(JSON.stringify(this.storeListArr))
            }
          } else {
            this.$q.notify({
              color: "red-5",
              textColor: "white",
              icon: "warning",
              position: "center",
              message: res.data.msg,
            });
          }
        })
    },
    showWarning(){
      this.$q.notify({
        color: "#FA7272",
        textColor: "white",
        icon: "warning",
        position: "center",
        message: '巡检待完成',
        
      });
    },
    showStoreList() {
      if(!this.showCate){
        this.showStore = true;
      }
    },
    //获取巡检表信息与试卷id
    initLoad() {
      if (this.orderId > 0) {
        this.axios
          .get(
             "/" + this.$ajaxPrefix.consumer +
           "/store/patrol/order/patrol/getOrder?id="+this.orderId
          )
          .then((res) => {

            if (res.data && res.data.code == 0) {
              console.log('initLoad',res.data)
               this.basicInfo.truename= res.data.data.remark
               this.basicInfo.store=res.data.data.storeid
               this.basicInfo.wdate=res.data.data.wdate
               this.basicInfo.edate=res.data.data.edate
               this.date = res.data.data.wdate + ' 至 '+ res.data.data.edate
              //  this.getPaper(res.data.data.paperId)
              this.getPaperInfos(res.data.data.paperId)
            } else {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: res.data.msg,
              });
            }
          })
          .catch(() => {
            this.loading = false;
            Toast("请求出错了");
          });
      }
    },
    //获取试卷
    getPaperInfos(id) {
        this.axios
          .post(
             "/" + this.$ajaxPrefix.consumer +
           "/store/patrol/order/home",
           {
             orderId:this.orderId,
            paperId:id
           }
          )
          .then((res) => {
            console.log(res);
            this.showCate = true
            if (res.data && res.data.code == 0) {
              console.log('store/patrol/order/home',res.data)
              this.paperTypeTree  = this.handleTree(res.data.data, "paperNoteId","paperParentId")
               let answerNum = 0;
               let questionNum = 0;
               res.data.data.map(v =>{
                 answerNum += v.currentNum; 
                 questionNum += v.total;  
               })
               this.answerNum = answerNum;
               this.questionNum = questionNum;
            } else {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: res.data.msg,
              });
            }
          })
          .catch(() => {
            this.loading = false;
            Toast("请求出错了");
          });
    },
    //获取试卷
    getPaper(id) {
        this.axios
          .get(
             "/" + this.$ajaxPrefix.consumer +
           "/store/patrol/paper/get?paperId="+id
          )
          .then((res) => {

            if (res.data && res.data.code == 0) {
              console.log('getPaper',res.data)
              this.paperItemsAll = res.data.data
              this.getPaperType(id)
              this.getAnswers()
            } else {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: res.data.msg,
              });
            }
          })
          .catch(() => {
            this.loading = false;
            Toast("请求出错了");
          });
    },
        //获取答案列表
    getAnswers() {
        this.axios
          .get(
             "/" + this.$ajaxPrefix.consumer +
           "/store/patrol/answer/getPaperQuestion?orderId="+this.orderId
          )
          .then((res) => {

            console.log('getPaperQuestion',res.data)
            if (res.data && res.data.code == 0) {
              if(res.data.data && res.data.data.answerList){
                res.data.data.answerList.map(answer =>{
                  //所有问题答题情况
                  this.paperItemsAll.map(paperItem =>{
                    if(paperItem.id == answer.questionId){
                       paperItem.answer = answer.content
                    }
                  })
                })
                //未答问题
                this.paperItemsAllNoAnswered =  this.paperItemsAll.filter(item =>{
                  return !item.answer
                })
              }
            } else {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: res.data.msg,
              });
            }
          })
    },
    //获取题目类型
    getPaperType(id) {
        this.axios
          .get(
             "/" + this.$ajaxPrefix.consumer +
            //  '/store/patrol/patrolList'
           "/store/patrol/paper/note/get?paperId=" + id
           ,
            {
              "pager": {
                "pageIndex": 1,
                "pageSize": 500,
                "rowsCount": 0,
                "start": 0
              },
              "queryMap": {
                "additionalProp1": "string",
                "additionalProp2": "string",
                "additionalProp3": "string"
              },
              "queryMapObject": {}
            })
          .then((res) => {

            if (res.data && res.data.code == 0) {
              console.log('getPaperType',res.data.data)
              let trees  = this.getChildTreeIds(this.handleTree(res.data.data, "id","parentId"));
              trees = trees.filter(tree =>{
                tree.hasItem = false;
                this.paperItemsAll.map(item =>{
                  if(tree.ids.indexOf(parseInt(item.noteId)) > -1){
                     tree.hasItem = true;
                  }
                })
                if(tree.hasItem && tree.children){
                  tree.children = tree.children.filter(leaf =>{
                        leaf.hasItem = false;
                        this.paperItemsAll.map(item =>{
                          if(leaf.id == parseInt(item.noteId)){
                            leaf.hasItem = true;
                          }
                        })
                        return  leaf.hasItem
                      })
                }
                return tree.hasItem
              })
              this.paperTypeTree =  trees || []
              this.showCate = true
            } else {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: res.data.msg,
              });
            }
          })
    },
    getChildTreeIds(trees){
      trees.map(tree =>{
        tree.ids = tree.ids || [];
        if(tree.children){
          tree.children = this.getChildTreeIds(tree.children)
          tree.children.map(child =>{
            tree.ids.push(child.id);
            tree.ids = tree.ids.concat(child.ids);
          })
          
        }
      })
      return trees
    },
     /**
     * 构造树型结构数据
     * @param {*} data 数据源
     * @param {*} id id字段 默认 'id'
     * @param {*} parentId 父节点字段 默认 'parentId'
     * @param {*} children 孩子节点字段 默认 'children'
     * @param {*} rootId 根Id 默认 0
     */
    handleTree(data, id, parentId, children, rootId) {
      console.log(data,999);
      id = id || 'id'
      parentId = parentId || 'parentId'
      children = children || 'children'
      rootId = rootId || Math.min.apply(Math, data.map(item => { return item[parentId] })) || 0
      //对源数据深度克隆
      const cloneData = JSON.parse(JSON.stringify(data))
      //循环所有项
      const treeData = cloneData.filter(father => {
        father.ids
        let branchArr = cloneData.filter(child => {
          //返回每一项的子级数组
          return father[id] === child[parentId]
        });
        branchArr.length > 0 ? father.children = branchArr : '';
        //返回第一层
        return father[parentId] === rootId;
      });
      return treeData != '' ? treeData : data;
    },
      completePaper(){
        console.log(123);
      this.$dialog.confirm({
          message: `确认完成巡检表, 完成后不可修改是否继续?`,
        }).then(() => {
          let postData = {
            id:this.orderId,
            isComplete:1
          };
          this.axios
          .post(
            "/" + this.$ajaxPrefix.consumer +
            "/store/patrol/order/edit",
            postData
          )
          .then((res) => {
            if (res.data && res.data.code == 0) {
              Toast("操作成功");
              this.isComplete = 1
            } else {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: res.data.msg,
              });
            }
          })
        }).catch(() => {

        });
  }
  },

};
